(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:OrderStartCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('OrderStartCtrl', OrderStartCtrl);

  function OrderStartCtrl($mdDialog, $analytics, $translate, CTAWidget) {
    var vm = this;

    $analytics.eventTrack($translate.instant('order.steps.analytics.started'), {
      category: $translate.instant('order.steps.analytics.registration.base'),
      action: $translate.instant('order.steps.analytics.started')
    });

    /**
     * Open a dialog with details of all card types
     */
    vm.openComparisonDialog = function openComparisonDialog() {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/card-comparison-dialog.tpl.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        controllerAs: 'comparisonDialog',
        controller: function comparisonDialog($mdDialog) {
          var vm = this;

          /**
           * Be able to close the current dialog
           */
          vm.closeDialog = function closeDialog() {
            $mdDialog.hide();
          };
        }
      });
    };

    vm.openCTAWidget = function openCTAWidget() {
      CTAWidget.open();
    };

    vm.orderSteps = [
      {
        icon: 'zmdi-city-alt',
        title: $translate.instant('order.orderStart.steps.1.title'),
        description: $translate.instant('order.orderStart.steps.1.description')
      },
      {
        icon: 'zmdi-card',
        title: $translate.instant('order.orderStart.steps.2.title'),
        hasCardComparison: true
      },
      {
        icon: 'zmdi-accounts',
        title: $translate.instant('order.orderStart.steps.3.title'),
        description: $translate.instant('order.orderStart.steps.3.description')
      },
      {
        icon: 'zmdi-money-box',
        title: $translate.instant('order.orderStart.steps.4.title')
      }
    ];
  }
}());
